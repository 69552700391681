<template>
  <div>
<!--    logo-->
    <el-row class="topLogo">
      <el-col :span="23"><img src="@/assets/layout/logo.png" style="width: 25vw" ></el-col>
      <el-col :span="1"><i class="iconfont icon-gengduo" @click="openRightDrawer"></i></el-col>
    </el-row>
<!-- 右侧弹出层 -->
    <rightDrawer ref="son"></rightDrawer>

    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 2vw 0 2vw 5vw">
      <el-breadcrumb-item style="font-size: 4.25vw" :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="font-size: 4.25vw">资料下载</el-breadcrumb-item>
    </el-breadcrumb>

    <img src="@/assets/mobile/moDomDowanload/bg.png" width="100%">

<!--    文档列表-->
    <el-row style="width: 95%;margin: 0 auto">
      <el-col :span="1" style="margin:5% 2% 0 0;"><img src="@/assets/technicalSupport/domDownload/icon1.png"></el-col>
      <el-col :span="10">
        <p class="listTitle">技术</p>
        <ul class="listUl">
          <li>磐石云桌面技术白皮书</li>
          <li>磐石云桌面部署手册</li>
          <li>磐石云桌面POC测试报告</li>
          <li>磐石云桌面POC测试报告</li>
        </ul>
      </el-col>
      <el-col :span="1" style="margin:5% 2% 0 0;"><img src="@/assets/technicalSupport/domDownload/icon2.png"></el-col>
      <el-col :span="11">
        <p class="listTitle">宣传</p>
        <ul class="listUl">
          <li>磐石云桌面宣传手册</li>
          <li>磐石云桌面云应用解决方案</li>
          <li>磐石云终端PS4405系列彩页</li>
          <li>磐石云终端PS8350系列彩页</li>
          <li>磐石云终端PS7500系列彩页</li>
          <li>磐石云终端PS2711系列彩页</li>
          <li>高价值应用上云服务平台</li>
          <li>磐石随机手册</li>
          <li>磐石系列产品报价</li>
        </ul>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import rightDrawer from "@/components/rightDrawer";

export default {
  name: "moDomDownload",
  components:{rightDrawer},
  data(){
    return{

    }
  },
  methods:{
    openRightDrawer(){
      this.$refs.son.showRightDrawer()
    }
  }
}
</script>

<style scoped>
/*重置样式*/
li{
  list-style: none;
}
a{
  text-decoration: none;
}
*{
  font-size: 3vw;
  color: #fff;
}
img{
  width: 100%;
}
/*
  1、清除重置样式  字体大小
  2、磐石动态  标题和详情的   单行显示

*/
/*页面样式*/
/*顶部logo*/
.topLogo{
  display: flex;
  align-items: center;
  padding: 3vw 4vw;
  border-bottom: 0.3vw solid #101010;
}
.icon-gengduo{
  font-size: 4vw;
  position: fixed;
  top: 1.5%;
  right: 5%;
  z-index: 2;
  background-color: #0c3056;
  padding: 1%;
}
/*顶部 右侧弹窗*/
.panshDrawer{
  padding: 0 4vw;
}
.panshDrawer li{
  margin: 8vw 0;
}
.panshDrawer li a{
  font-size: 4.25vw;
}
>>> .el-drawer__header{
  font-size: 4.5vw;
  background-color: #09283d;
  margin-bottom: 0;
  padding-bottom: 20px;
}
>>> .el-drawer__body{
  background-color: #09283d;
}

/*文档列表*/
.listTitle{
  line-height: 13vw;
  font-size: 4vw;
  color: #101010;
}
.listUl li{
  line-height: 9vw;
  font-size: 3.25vw;
  color: #496AB1;
  cursor: pointer;
}
</style>